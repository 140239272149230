<template>
    <div class="div-swiper">
        <van-swipe :autoplay="5000"
                   :duration="1500"
                   indicator-color="#4d77fd"
                   @change="onChangeSwiper"
        >
            <van-swipe-item
                    v-for="(item, index) in LikeList" :key="index"
                    class="item-images"
                    @click="SwiperClick(item)"
            >
                <img :src="item.image" class="images"/>
            </van-swipe-item>
            <template v-if="LikeList.length == 0">
                <van-swipe-item>
                    <p class="LikeList_active">
                        <span>暂无图片展示</span>
                    </p>
                </van-swipe-item>
            </template>
        </van-swipe>
    </div>
</template>

<script>
    export default {
        name: "Swiper",
        props: {
            LikeList: Array
        },
        methods: {
            onChangeSwiper(index) {
                this.$emit('onChangeSwiper', index)
            },
            SwiperClick(item) {
                this.$emit('SwiperClick', item)
            }
        }
    }
</script>

<style scoped>
    .van-swipe-item {
        padding: 0 20px;
    }

    .van-swipe {
        border-radius: 5px;
    }

    .div-swiper {
        padding: 40px 0 0;
    }

    .images {
        border-radius: 10px;
        width: 100%;
        height: 300px;
        background: url("../../assets/images/book_cover_def.png") no-repeat;
        background-size: cover;
        display: inline-block;
    }
    .LikeList_active{
        height: 300px;
        background: #fafafa;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
    }
</style>