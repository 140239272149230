<template>
    <div class="div">
        <div class="book-list">
            <template v-for="item in bookList">
                <div class="book-div" @click="detailsClick(item)">
                    <p><img v-lazy="item.coverpic" class="book-img"/></p>
                    <span class="points_vip" v-if="item.isvip =='1'">VIP</span>
                    <template v-if="item.isvip =='0'">
                        <p class="points" v-if="item.average_score != 0">{{item.average_score}}分</p>
                    </template>
                    <p class="book-title">{{item.title}}</p>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Typography3",
        props:{
            bookList:Array,
        },
        data() {
            return {
            }
        },
        methods:{
            detailsClick(item){
                this.$emit('detailsClick',item)
            }
        }
    }
</script>

<style scoped>
    .book-title {
        font-size: 26px;
        color: #303133;
        margin-top: 19px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 198px;
        line-height: 26px;
    }

    .book-img {
        width: 198px;
        height: 277px;
        border-radius: 10px;
        display: inline-block;
        background: url("../../assets/images/book_cover_def.png") no-repeat;
        background-size: cover;
    }

    .book-list {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #F2F5F8;
        padding-bottom: 40px;
        margin-bottom: 50px;
    }

    .book-div {
        position: relative;
        margin-right: 38px;
        margin-top: 40px;
    }

    .book-div:nth-of-type(3n+3) {
        margin-right: 0 !important;
    }
</style>