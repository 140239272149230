<template>
    <div class="div">
        <div class="book-list">
            <template v-for="item in bookList.slice(0,2)">
                <div class="book-top" @click="detailsClick(item)">
                    <p><img v-lazy="item.coverpic" class="book_img1"/></p>
                    <span class="points_vip" v-if="item.isvip =='1'">VIP</span>
                    <template v-if="item.isvip =='0'">
                        <p class="points" v-if="item.average_score != 0">{{item.average_score}}分</p>
                    </template>
                    <div class="book-right">
                        <p class="author-title">{{item.title}}</p>
                        <p class="author-list">
                            <img src="../../assets/images/author_icon.png" alt="" class="author_icon">
                            <span class="author">{{item.author}}</span>
                        </p>
                    </div>
                </div>
            </template>
        </div>

        <div class="book-button">
            <template v-for="item in bookList.slice(3,15)">
                <div class="book-button-div" @click="detailsClick(item)">
                    <p><img v-lazy="item.coverpic" class="book_img"/></p>
                    <p class="points" v-if="item.average_score != 0">{{average_score}} <span>分</span></p>
                    <p class="title">{{item.title}}</p>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            bookList: Array,
        },
        name: "Typography7",
        methods: {
            detailsClick(item) {
                this.$emit('detailsClick', item)
            }
        }
    }
</script>

<style scoped>
    .author-list {
        display: flex;
        align-items: center;
    }

    .author-title {
        font-size: 26px;
        color: #303133;
        margin-bottom: 86px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 150px;
    }

    .book-list {
        display: flex;
        justify-content: space-between;
    }

    .book-top {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 50px;
        position: relative;
    }

    .book_img1 {
        width: 145px;
        height: 203px;
        border-radius: 10px;
        margin-right: 24px;
        display: inline-block;

        background: url("../../assets/images/book_cover_def.png") no-repeat;
        background-size: cover;
    }

    .author {
        width: 107px;
        font-size: 22px;
        font-weight: 500;
        color: rgba(191, 194, 204, 1);
        line-height: 36px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .author_icon {
        width: 20px;
        height: 23px;
        margin-right: 10px;
    }

    .title {
        font-size: 26px;
        color: #303133;
        width: 145px;
        height: 27px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 20px;
    }

    .book-button-div {
        position: relative;
        margin-right: 30px;
        margin-top: 40px;
    }

    .book-button-div:nth-of-type(4n+4) {
        margin-right: 0 !important;
    }

    .book_img {
        width: 145px;
        height: 203px;
        border-radius: 10px;
        display: inline-block;
        background: url("../../assets/images/book_cover_def.png") no-repeat;
        background-size: cover;
    }

    .book-button {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #F2F5F8;
        padding-bottom: 40px;
        margin-bottom: 50px;
    }
</style>