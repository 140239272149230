<template>
    <div class="div">
        <div class="book-button">
            <template v-for="item in bookList">
                <div class="book-button-div" @click="detailsClick(item)">
                    <p><img v-lazy="item.coverpic" class="book_img"/></p>
                    <span class="points_vip" v-if="item.isvip =='1'">VIP</span>
                    <template v-if="item.isvip =='0'">
                        <p class="points" v-if="item.average_score != 0">{{item.average_score}}分</p>
                    </template>
                    <p class="title">{{item.title}}</p>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Typography5",
        props: {
            bookList: Array,
        },
        methods: {
            detailsClick(item) {
                this.$emit('detailsClick', item)
            }
        }
    }
</script>

<style scoped>
    .title {
        font-size: 26px;
        color: #303133;
        width: 145px;
        height: 27px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 20px;
    }

    .book-button-div {
        position: relative;
        margin-right: 30px;
        margin-top: 40px;
    }

    .book-button-div:nth-of-type(4n+4) {
        margin-right: 0 !important;
    }

    .book_img {
        width: 145px;
        height: 203px;
        border-radius: 10px;
        display: inline-block;
        background: url("../../assets/images/book_cover_def.png") no-repeat;
        background-size: cover;
    }

    .book-button {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #F2F5F8;
        padding-bottom: 40px;
        margin-bottom: 50px;
    }
</style>