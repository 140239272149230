<template>
    <div class="no-list">
        <p><img src="../assets/images/no.png" alt="" class="no"></p>
        <p class="no-title">{{title}}</p>
    </div>
</template>

<script>
    export default {
        name: "No",
        props: {
            title: String,
        }
    }
</script>

<style scoped>
    .no-title {
        font-size: 28px;
        color: #909399;
        margin-top: 28px;
    }

    .no-list {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .no {
        width: 199px;
        height: 174px;
    }
</style>