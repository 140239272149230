<template>
    <div class="like">
        <div class="title-div">
            <img src="../../assets/images/title.png" alt="" class="title-img">
        </div>
        <div class="book_list">
            <template v-for="(item,index) in LikeList">
                <div class="book_div">
                    <div @click="detailsClick(item)">
                        <img :src="item.coverpic" alt="" class="book_img">
                        <span class="points_vip" v-if="item.isvip =='1'">VIP</span>
                        <p class="book_img_text">{{item.title}}</p>
                    </div>
                    <p class="xhao" @click="remove_like(item,index)">
                        <van-icon name="cross" class="#fff" size="12"/>
                    </p>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Like",
        props: {
            LikeList: Array,
        },
        data() {
            return {}
        },
        methods: {
            detailsClick(item){
                this.$emit('detailsClick',item)
            },
            remove_like(item,index) {
                this.$axios.post('/api/bookstores/remove_like', {
                    id: item.id
                })
                    .then(res => {
                        var res = res.data
                        if (res.code == 200) {
                            this.$emit("remove_like", res.data,index)
                        }
                    })
            }
        }
    }
</script>

<style scoped>
    .xhao {
        padding: 5px;
        width: 29px;
        height: 29px;
        background: #fff;
        opacity: 0.5;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px auto 0;
    }

    .like {
        padding: 40px;
        height: 500px;
        overflow: hidden;
    }

    .book_div {
        text-align: center;
        position: relative;
    }

    .title-div {
        text-align: center;
        padding-bottom: 40px;
    }

    .title-img {
        width: 400px;
        height: 32px;
    }

    .book_img {
        width: 190px;
        height: 266px;
        display: inline-block;
        background: url("../../assets/images/book_cover_def.png") no-repeat;
        background-size: cover;
        border-radius: 10px;
    }

    .book_img_text {
        color: #fff;
        font-size: 26px;
        width: 190px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 20px;
    }

    .book_list {
        display: flex;
        justify-content: space-between;
    }
</style>