<template>
    <div class="library-images">
        <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    :finished-text="finished_text"
                    @load="onLoad"
            >
                <div class="library—title">
                    <div class="search__div" @click="searchClick">
                        <img class="search_icon" src="../../assets/images/search_icon.png" alt="" v-if="!scrollY_show">
                        <img class="search_icon search_icon2" src="../../assets/images/search_icon2.png" alt="" v-else>
                    </div>
                    <van-tabs v-model="active"
                              sticky
                              animated
                              swipeable
                              title-inactive-color="#fff"
                              title-active-color="#fff"
                              color="#fff"
                              :border="false"
                              background="rgba(0,0,0,0)"
                              class="library-tabs"
                              :ellipsis="false"
                              @change="changeClick"
                    >
                        <template v-for="item in title_tabs">
                            <van-tab :title="item.title">
                                <Like :LikeList="LikeList" @remove_like="remove_like"
                                      @detailsClick="detailsClick" v-if="item.type == 0"></Like>

                                <Swiper :LikeList="LikeList"
                                        @SwiperClick="SwiperClick"
                                        @onChangeSwiper="onChangeSwiper"
                                        v-if="item.type == 1"></Swiper>
                                <div class="top_list" :class="title_type.type == 1?'top_list-active':''">
                                    <template v-for="item in column">
                                        <template v-if="item.style == 0">
                                            <TitleList :title="item"></TitleList>
                                            <Typography1 :bookList="item.list"
                                                         @detailsClick="detailsClick"></Typography1>
                                        </template>
                                        <template v-if="item.style == 1">
                                            <TitleList :title="item"></TitleList>
                                            <Typography2 :bookList="item.list"
                                                         @detailsClick="detailsClick"></Typography2>
                                        </template>
                                        <template v-if="item.style == 2">
                                            <TitleList :title="item"></TitleList>
                                            <Typography3 :bookList="item.list"
                                                         @detailsClick="detailsClick"></Typography3>
                                        </template>
                                        <template v-if="item.style == 3">
                                            <TitleList :title="item"></TitleList>
                                            <Typography4 :bookList="item.list"
                                                         @detailsClick="detailsClick"></Typography4>
                                        </template>
                                        <template v-if="item.style == 4">
                                            <TitleList :title="item"></TitleList>
                                            <Typography5 :bookList="item.list"
                                                         @detailsClick="detailsClick"></Typography5>
                                        </template>
                                        <template v-if="item.style == 5">
                                            <TitleList :title="item"></TitleList>
                                            <Typography6 :bookList="item.list"
                                                         @detailsClick="detailsClick"></Typography6>
                                        </template>
                                        <template v-if="item.style == 6">
                                            <TitleList :title="item"></TitleList>
                                            <Typography7 :bookList="item.list"
                                                         @detailsClick="detailsClick"></Typography7>
                                        </template>
                                        <template v-if="item.style == 7">
                                            <TitleList :title="item"></TitleList>
                                            <Typography8 :bookList="item.list"
                                                         @detailsClick="detailsClick"></Typography8>
                                        </template>
                                    </template>
                                </div>
                            </van-tab>
                        </template>
                    </van-tabs>
                    <div class="wel" v-if="loading_show">
                        <van-loading size="20px">加载中...</van-loading>
                    </div>
                    <div class="no" v-if="column.length == 0 && !loading_show">
                        <No title="暂无书籍"></No>
                    </div>
                </div>
                <!--        背景高斯模糊-->
                <div class="book_img_div">
                    <div class="book_img">
                        <template v-if="title_type.type == 0">
                            <div class="bg" v-if="LikeList[1]">
                                <van-image :src="LikeList[1].coverpic" class="home_top"/>
                            </div>
                        </template>
                        <template v-if="title_type.type == 1">
                            <div class="bg swiper" v-if="Swiper_bg">
                                <van-image :src="Swiper_bg.image" class="home_top"/>
                            </div>
                        </template>
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
    import Like from '../../components/LibraryView/Like'
    import Swiper from "../../components/LibraryView/Swiper"
    import TitleList from '../../components/LibraryView/TitleList'
    import Typography1 from '../../components/LibraryView/Typography1'
    import Typography2 from '../../components/LibraryView/Typography2'
    import Typography3 from '../../components/LibraryView/Typography3'
    import Typography4 from '../../components/LibraryView/Typography4'
    import Typography5 from '../../components/LibraryView/Typography5'
    import Typography6 from '../../components/LibraryView/Typography6'
    import Typography7 from '../../components/LibraryView/Typography7'
    import Typography8 from '../../components/LibraryView/Typography8'
    import No from "../../components/No"

    export default {
        components: {
            Like,
            Swiper,
            TitleList,
            Typography1,
            Typography2,
            Typography3,
            Typography4,
            Typography5,
            Typography6,
            Typography7,
            Typography8,
            No,
        },
        name: "Library",
        data() {
            return {
                loading_show: true,
                active: 0,
                title_tabs: [],
                scrollY_show: false,
                isLoading: false,
                finished: false,
                loading: false,
                finished_text: '我是有底线的~',
                page: 1,
                page_size: 15,
                LikeList: [],
                column: [],
                is_show: true,
                title_type: {},
                Swiper_bg: {},
            }
        },
        created() {
            window.addEventListener('scroll', this.handleScroll)
            this.channelGet()
        },
        methods: {
            onChangeSwiper(index) {
                this.Swiper_bg = this.LikeList[index]
            },
            SwiperClick(item) {
                this.$router.push({
                    path: '/details',
                    query: {
                        id: item.anid
                    }
                })
            },
            detailsClick(e) {
                this.$router.push({
                    path: '/details',
                    query: {
                        id: e.id
                    }
                })
            },
            remove_like(item, index) {
                this.$set(this.LikeList, index, item);
            },
            channelGet() {
                if (!this.is_show) return
                this.$axios.get('/api/bookstores/channel')
                    .then(res => {
                        this.is_show = false
                        var res = res.data.data
                        this.title_tabs = res
                        this.title_type = res[0]
                        this.channel_data(res[0].id)
                    })
            },
            channel_data(id) {
                this.$axios.get('/api/bookstores/channel_data', {
                    params: {
                        channel_id: id,
                        p: this.page,
                        page_size: this.page_size
                    }
                })
                    .then(res => {
                        this.loading_show = false
                        this.finished_text = '我是有底线的~'
                        var res = res.data.data
                        this.LikeList = res.ad
                        if (this.title_type.id == 1) {
                            this.Swiper_bg = res.ad[0]
                        }
                        this.column = res.column
                    })
            },
            onRefresh() {
                setTimeout(() => {
                    this.is_show = true
                    this.channelGet()
                    this.isLoading = false
                    this.finished = false;
                }, 1000);
            },
            onLoad() {
                this.loading = false;
                this.finished = true;
                // setTimeout(() => {
                //
                // }, 1000);
            },
            handleScroll() {
                let scrollY = document.documentElement.scrollTop
                if (scrollY >= 20) {
                    this.scrollY_show = true
                } else {
                    this.scrollY_show = false
                }
            },
            changeClick() {
                this.finished_text = ''
                this.loading_show = true
                this.column = []
                // this.LikeList = []
                this.channel_data(this.title_tabs[this.active].id)
                this.title_type = this.title_tabs[this.active]
            },
            searchClick() {
                this.$router.push({
                    path: '/search'
                })
            },
        }
    }
</script>

<style scoped>
    .book_img_div {
        width: 100%;
        margin: auto;
        overflow: hidden;
    }

    .bg {
        position: relative;
        left: -51%;
        width: 200%;
        height: 680px;
        border-radius: 0 0 50% 50%;
        overflow: hidden;
    }

    .swiper {
        height: 350px !important;
    }

    .home_top {
        width: 100%;
        height: 100%;
        filter: blur(10px);
        overflow: hidden;
        /*background: url("../../assets/images/book_cover_def.png")  no-repeat;*/
        background: #98664A;
        background-size: cover;
        display: inline-block;
    }

    .book_img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }


    .top_list {
        margin-top: 120px;
    }

    .top_list-active {
        margin-top: 50px;
    }

    .library-tabs {
        position: relative;
    }

    .search__div {
        position: relative;
        top: 0px;
        right: 0;
        z-index: 122;
    }

    .search_icon2 {
        position: fixed !important;
        right: 40px !important;
    }

    .search_icon {
        position: absolute;
        top: 30px;
        right: 40px;
        width: 30px;
        z-index: 122;
        display: flex;
        justify-content: flex-end;
    }

    .search_icon img {
        width: 30px;
        height: 32px;
    }

    .library-images {
        position: relative;
    }


    .library—title {
        padding: 40px 0 0 0;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9;
    }
</style>