<template>
    <div class="div">
        <div class="phy">
            <div class="phy_title">{{title.title}}</div>
            <div class="more_icon_div" @click="moreClick(title)" v-if="title.more != 0">
                更多
                <img src="../../assets/images/more_icon.png" alt="" class="more_icon">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TitleList",
        props: {
            title: Object,
        },
        methods: {
            moreClick(item) {
                this.$router.push({
                    path: '/more?id=' + item.id
                })
            }
        }
    }
</script>

<style scoped>
    .phy {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }

    .more_icon_div {
        display: flex;
        align-items: center;
        font-size: 24px;
        color: #0A1C33;
    }

    .phy_title {
        color: #0A1C33;
        font-size: 34px;
        font-weight: bold;
    }

    .more_icon {
        width: 24px;
        height: 24px;
        margin-left: 5px;
    }
</style>